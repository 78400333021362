.color-primary {
  color: #FFFFFF
}

.color-secondary {
  color: #c7c7c7
}

.color-background {
  color: #1E1E1E
}

.color-bg-background {
  background: #1E1E1E
}

.color-card-background {
  color: #292929
}

.color-bg-card-background {
  background: #292929
}

.color-accent {
  color: #E84A5F
}

.color-border-accent {
  border-color: #E84A5F;
  border-style: solid;
  border-width: 2px;
}

.disp-none {
  display: none;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-landing {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: min(70vw, 1500px);
}

.landing-description {
  flex: 1;
  min-width: 20rem;
}

.landing-header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.landing-description h1 {
  font-family: 'Poppins';
  font-size: 3rem;
  margin: 0;
}

.landing-description h2 {
  font-family: 'Open Sans';
  margin: 0;
}

.landing-description p {
  font-family: 'Open Sans';
  font-weight: 100;
}

.landing-cta {
  flex: 1;
  text-align: center;
  min-width: 20rem;
}

.landing-cta h2 {
  font-family: 'Poppins';
  font-size: 2rem;
}

.landing-cta-button {
  padding: 1rem;
  border-radius: 5rem;
  font-family: 'Poppins';
  font-size: 1rem;
  cursor: pointer;
}

.landing-cta-button-icon {
  margin-right: 0.5rem;
}

.page-admin {
  height: 100%;
  display: flex;
  justify-content: center;
}

.admin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(70vw, 1500px);
}

.admin-content h1 {
  font-family: 'Poppins';
}

.admin-top-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 95vw;
}

.admin-top-bar-title {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-weight: 900;
}

.admin-top-bar-title h1 {
  font-family: 'Poppins';
  font-size: 3rem;
}

.admin-top-bar-button {
  margin: 2rem;
  padding: 1rem;
  border-radius: 3rem;
  font-family: 'Poppins';
  font-size: 1rem;
  cursor: pointer;
}

.admin-info-card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 2rem;
  font-family: 'Open Sans';
  box-shadow: 2px 2px 10px black;
  width: min(70vw, 750px);
  gap: 1rem;
}

.value-disp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.value-disp-stacked {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.value-disp h3, 
.value-disp-stacked h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1;
  font-size: 1rem;
}

.value-disp span, 
.value-disp-stacked span {
  font-family: 'Ubuntu Mono';
  font-size: 1rem;
  background-color: #171717;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  flex: 3;
}

.copy-button {
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.admin-tutorial {
  margin-bottom: 5rem;
}

.admin-tokens-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  text-align: center;
}

.admin-tokens-button {
  background: none;
  border-radius: 2rem;
  padding: 1rem;
  font-family: 'Poppins';
  font-size: 1rem;
  cursor: pointer;
  margin: 1rem;
  flex: 1;
  text-decoration: none;
  
}

.admin-tokens-button span {
  font-family: 'Open Sans';
}

.admin-subscription-portal-button {
  background: none;
  font-family: 'Poppins';
  border-radius: 2rem;
  padding: 1rem;
  font-size:1rem;
  cursor: pointer;
}

.modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback */
  background-color: rgba(0,0,0,0.4);  
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-redirect {
  border-radius: 2rem;
  padding: 2rem;
  box-shadow: 2px 2px 10px black;
  font-family: 'Poppins';
  font-size: 0.75rem;
  text-align: center;
}

.modal-redirect-spinner {
  margin: 2rem;
  font-size: 50px;
}

.admin-footer {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: 'Open Sans';
}

.admin-footer-copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-footer-email-container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.admin-footer-email-container a {
  text-decoration: none;
}
